import * as React from "react"
import { useCookies } from 'react-cookie';
import {graphql, useStaticQuery} from 'gatsby';


function CookieConsent() {
  const [cookies, setCookie] = useCookies(['cookie-consent']);
  const { strapiCookieBanner } = useStaticQuery(
    graphql`
      query {
        strapiCookieBanner {
          ConfirmButtonText
          requiredCookieTitel
          headline
          description
        }
      }
    `
  )

  function onChange() {
    setCookie('cookie-consent', true, { path: '/' });
  }

  return (
    <div className={`${cookies['cookie-consent'] ? 'display-none' : ''}`}>
      <div className='overlay'/>
      <div className='cookie-consent '>
        <h2>{strapiCookieBanner.headline}</h2>
        <div dangerouslySetInnerHTML={{__html: strapiCookieBanner.description}} />
        <p className="text-center">
          {strapiCookieBanner.requiredCookieTitel} <input type="checkbox" checked disabled />
        </p>
        <br/>
        <p  className="text-center">
          <button onClick={onChange}>{strapiCookieBanner.ConfirmButtonText}</button>
        </p>
      </div>
    </div>
  );

}

export default CookieConsent;
