import * as React from "react"
import * as PropTypes from "prop-types"
import Section, {SectionProps} from './section';

interface ExtendedSectionProps extends SectionProps {
  sortOrder: number;
}

export interface SectionsProps {
  sections: ExtendedSectionProps[]
}

const Sections = ({ sections }: SectionsProps) => {
  sections.sort((a,b) => a.sortOrder - b.sortOrder)

  return (
    <>
      {sections.map((section, index) => (
        <Section key={index} section={section} />
      ))}
    </>
  );
}

Sections.propTypes = {
  sections: PropTypes.array
}

Sections.defaultProps = {
  sections: []
}

export default Sections
