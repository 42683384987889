import * as React from "react"
import { graphql, PageProps  } from "gatsby";
import { CookiesProvider } from 'react-cookie';


import Layout from "../components/layout"
import Seo from "../components/seo"
import Main, {MainProps} from '../components/main';
import Sections, {SectionsProps} from '../components/sections';

import "../styles/style.css"

import "../styles/namari-color.css"

import "../styles/font-awesome.css"
import "../styles/accordion.css"

import "../styles/animate.css"
import "../styles/jquery-modal.min.css"
import "../styles/modal.css"

import "../styles/main.css"
import CookieConsent from '../components/cookieConsent';

interface strapiHomepageProps extends MainProps, SectionsProps {}

type IndexPageProps = PageProps<{
  strapiHomepage: strapiHomepageProps
}>

const IndexPage = ({ data }: IndexPageProps) => {
  const { strapiHomepage } = data;

  return (
    <CookiesProvider>
      <Layout>
        <Seo title="Home" />
        <Main
          heroImage={strapiHomepage.heroImage}
          headline={strapiHomepage.headline}
          description={strapiHomepage.description}
        />
        <Sections sections={strapiHomepage.sections} />
        <CookieConsent />
      </Layout>
    </CookiesProvider>
  )
}

export const query = graphql`
  query {
    strapiHomepage {
      headline
      description
      separator
      sections {
        backgroundColor
        subHeadline
        headline
        description
        separator
        anchor
        separator
        paddingBottom
        sortOrder
        accordions {
          description
          headline
        }
      }
      heroImage {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(
                layout: CONSTRAINED,
                placeholder: TRACED_SVG,
                formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;

export default IndexPage
