import * as React from "react"
import * as PropTypes from "prop-types"

import Collapsible from 'react-collapsible';

export interface AccordionsProps {
  accordions?: Array<{
    headline: string,
    description?: string
  }>
}

const Accordions = ({ accordions }: AccordionsProps) => (
  <div className="around-accordions">
    <div className="row no-padding-bottom no-margin clearfix">
      <div className="col-1">
        {accordions.map(({headline, description}, index) => (
          <Collapsible
            key={index}
            classParentString="accordion"
            triggerTagName="div"
            triggerClassName="title closed"
            triggerOpenedClassName="title open"
            trigger={<h4><b>{headline}</b></h4>}
            contentOuterClassName="content"
            contentInnerClassName="row no-padding-bottom no-margin clearfix"
          >
            <div className="col-2-3 mobile-fix-no-padding-bottom">
              <p dangerouslySetInnerHTML={{__html: description}} />
            </div>
          </Collapsible>
        ))}
      </div>
    </div>
  </div>
);

Accordions.propTypes = {
  accordions: PropTypes.arrayOf(PropTypes.shape({
    headline: PropTypes.string.isRequired,
    description: PropTypes.string,
  }))
}

export default Accordions
