import * as React from "react"
import * as PropTypes from "prop-types"
import Accordion, {AccordionsProps} from './accordion';

interface props extends AccordionsProps {
    subHeadline?: string;
    headline?: string;
    separator?: Boolean;
    description?: string;
    backgroundColor?: string;
    anchor?: string;
    paddingBottom?: Boolean;
}

export interface SectionProps extends AccordionsProps {
  section: props
}

const Section = ({ section }: SectionProps) => (
  <section id={section.anchor && section.anchor} className="scrollto clearfix" style={{backgroundColor: section.backgroundColor}}>

    <div className={`row clearfix ${!section.paddingBottom ? 'no-padding-bottom' : ''}`}>

      <div className="col-1">
        <div className="section-heading">
          {section.subHeadline &&
            <h3>{section.subHeadline}</h3>
          }
          {section.headline &&
            <h2 className={section.separator ? 'section-title' : 'section-title dash-none'}>{section.headline}</h2>
          }
        </div>
      </div>

      {section.description &&
        <div dangerouslySetInnerHTML={{__html: section.description}} />
      }
    </div>

    {section.accordions.length > 0 &&
      <Accordion accordions={section.accordions}/>
    }

  </section>
);

Section.propTypes = {
  section: PropTypes.shape({
    subHeadline: PropTypes.string,
    headline: PropTypes.string,
    separator: PropTypes.bool,
    description: PropTypes.string,
    backgroundColor: PropTypes.string,
    accordions: PropTypes.array,
    anchor: PropTypes.string,
    paddingBottom: PropTypes.bool
  }).isRequired
}

export default Section
